import '../styles/main.scss';
import {
  Alert,
  Button,
  Heading,
  Image,
  Surface,
  Text,
} from '@quality24/design-system';
import { HeadFC } from 'gatsby';
import * as React from 'react';
import classNames from 'classnames';

import SEO from '../components/containers/SEO';
import MainTemplate from '../components/templates/MainTemplate';
import DataInquiryForm from '../components/organisms/DataInquiryForm';

import SquareGraphic from '../assets/graphic-1.inline.svg';
import lgpdImage from '../assets/lgpd.png';
import * as styles from './portaldotitular.module.scss';

const DataAgreementHero = () => (
  <div className={classNames(styles.title, 'container row')}>
    <div className="col-12 col-md-6 col-lg-8">
      <Heading className="mb-5" as="h1" size="lg2">
        Seja bem-vindo(a) ao <br />
        <strong>Portal do Titular</strong>
      </Heading>
    </div>
  </div>
);

const DataAgreementPage: React.FunctionComponent = () => {
  const [emailSent, setEmailSent] = React.useState(false);

  const formRef = React.useRef<HTMLDivElement>(null);
  const legalRef = React.useRef<HTMLDivElement>(null);

  return (
    <MainTemplate heroContent={<DataAgreementHero />}>
      <div
        className={classNames(styles.firstRow, 'container row flex-items-end')}
      >
        <div className="col-12 col-lg-8">
          <Text size="lg2">
            Nos comprometemos com a proteção de seus dados pessoais em respeito
            à Lei Geral de Proteção de Dados (Lei n.º 13.709/2018). Por isso,
            disponibilizamos o Portal do Titular, um canal de comunicação para
            que você possa realizar solicitações sobre seus dados pessoais,
            colocando em prática seus direitos.
          </Text>

          <div className="d-flex gap-4 mt-4">
            <Button onClick={() => formRef.current?.scrollIntoView()}>
              Fazer solicitação
            </Button>
            <Button
              variant="link"
              onClick={() => legalRef.current?.scrollIntoView()}
            >
              Conheça seus direitos
            </Button>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-2 d-none d-lg-block">
          <Image src={lgpdImage} alt="LGPD" />
        </div>
      </div>

      {/* Inquiry form */}
      <div id={styles.lgpdForm} ref={formRef} className="container">
        <Surface className="bg-gradient-45" color="white">
          <Heading className="mb-3" as="h2" size="lg2" weight="bold">
            Solicitação do Titular
          </Heading>

          <div className="row g-9">
            <div className="col-12 col-md-6 col-lg-8">
              {emailSent && (
                <Alert
                  variant="success"
                  className="mt-3"
                  onDismiss={() => setEmailSent(false)}
                >
                  Seu contato foi enviado com sucesso!
                </Alert>
              )}
              <DataInquiryForm onSuccess={() => setEmailSent(true)} />
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <Heading className="mb-4" as="h2" weight="bold">
                DPO (Encarregado)
              </Heading>
              <Text size="lg2" weight="bold">
                Edson Nakada
              </Text>
              <Text size="lg2">dpo@quality24.com.br</Text>
            </div>
          </div>
        </Surface>
      </div>

      {/* Legal section */}
      <div id={styles.legalSection} ref={legalRef} className="container">
        <Heading
          className="mb-5"
          as="h2"
          size="xl"
          weight="bold"
          color="primary"
        >
          Direitos dos Titulares
        </Heading>

        <Text className="mb-3" size="lg2">
          O titular dos DADOS tem, dentre outros, direito a obter do
          controlador, em relação aos dados do titular por ela tratados, a
          qualquer momento e mediante requisição:
        </Text>

        <Text as="span" size="lg2">
          <ul>
            <li>I. confirmação da existência de tratamento;</li>
            <li>II. acesso aos dados;</li>
            <li>
              III. correção de dados incompletos, inexatos ou desatualizados;
            </li>
            <li>
              IV. anonimização, bloqueio ou eliminação de dados desnecessários,
              excessivos ou tratados em desconformidade com o disposto nesta
              Lei;
            </li>
            <li>
              V. portabilidade dos dados a outro fornecedor de serviço ou
              produto, mediante requisição expressa, de acordo com a
              regulamentação da autoridade nacional, observados os segredos
              comercial e industrial;
            </li>
            <li>
              VI. eliminação dos dados pessoais tratados com o consentimento do
              titular, exceto nas hipóteses previstas no art. 16 a Lei Geral de
              Proteção de Dados;
            </li>
            <li>
              VII. informação das entidades públicas e privadas com as quais o
              controlador realizou uso compartilhado de dados;
            </li>
            <li>
              VIII. informação sobre a possibilidade de não fornecer
              consentimento e sobre as consequências da negativa;
            </li>
            <li>IX. revogação do consentimento;</li>
          </ul>
        </Text>
      </div>

      <SquareGraphic className={styles.graphic} />
    </MainTemplate>
  );
};

export default DataAgreementPage;

export const Head: HeadFC = () => (
  <SEO
    title="Portal do Titular | Quality24"
    description="Faça aqui requisições a respeito de seus dados"
  />
);
