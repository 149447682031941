import { useFormik } from 'formik';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { faSend } from '@fortawesome/pro-regular-svg-icons';
import {
  Alert,
  Button,
  Icon,
  Input,
  MaskedInput,
  Spinner,
  TextArea,
} from '@quality24/design-system';
import classNames from 'classnames';
import * as Yup from 'yup';

import { sendDataInquiryEmail } from '../../../services/email';
import { formatDataInquiryEmailParams } from '../../../utils/email';
import * as styles from './DataInquiryForm.module.scss';

export interface FormValues extends Record<string, string> {
  name: string;
  email: string;
  document: string;
  message: string;
}

/**
 * Form initial values
 */
const initialValues: FormValues = {
  name: '',
  email: '',
  document: '',
  message: '',
};

/**
 * Form validation schema
 */
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Campo Obrigatório'),
  email: Yup.string().email('Email inválido').required('Campo Obrigatório'),
  document: Yup.string().required('Campo Obrigatório'),
  message: Yup.string().required('Campo Obrigatório'),
});

export interface Props {
  className?: string;
  formatParams?: (values: FormValues) => Record<string, string>;
  sendEmail?: (params: Record<string, string>) => Promise<unknown>;
  onSuccess: () => void;
}

const DataInquiryForm: React.FunctionComponent<Props> = ({
  className,
  formatParams = formatDataInquiryEmailParams,
  sendEmail = sendDataInquiryEmail,
  onSuccess,
}) => {
  // Create ref to recaptcha component
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const [emailError, setEmailError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  /**
   * Handles the form submission
   */
  const handleFormSubmit = React.useCallback(
    async (values: FormValues): Promise<void> => {
      if (!recaptchaRef || !recaptchaRef.current) return;

      try {
        // validate reCAPTCHA
        await recaptchaRef.current.execute();

        // send email
        setLoading(true);
        await sendEmail(formatParams(values));
        onSuccess();
      } catch (error) {
        setEmailError(true);
      } finally {
        setLoading(false);
      }
    },
    [recaptchaRef, formatParams, onSuccess, sendEmail],
  );

  // Create the formik state
  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <form
      className={classNames(styles.form, className)}
      onSubmit={handleSubmit}
      noValidate
    >
      {/* Error when sending form */}
      {emailError && (
        <Alert variant="danger" onDismiss={() => setEmailError(false)}>
          Não conseguimos enviar sua solicitação, por favor tente novamente.
        </Alert>
      )}

      {/* Form fields */}
      <div className="d-flex flex-column gap-3 mb-4">
        <Input
          name="name"
          label="Nome Completo"
          placeholder="Digite seu nome completo"
          helperText="Campo Obrigatório"
          value={values.name}
          errorText={(touched.name && errors.name) || ''}
          disabled={loading}
          onChange={handleChange}
        />

        <MaskedInput
          mask="999.999.999-99"
          name="document"
          label="CPF"
          placeholder="Digite seu CPF"
          helperText="Campo Obrigatório"
          value={values.document}
          errorText={(touched.document && errors.document) || ''}
          disabled={loading}
          onChange={handleChange}
        />

        <Input
          name="email"
          type="email"
          label="E-mail"
          placeholder="Digite seu e-mail"
          helperText="Campo Obrigatório"
          value={values.email}
          errorText={(touched.email && errors.email) || ''}
          disabled={loading}
          onChange={handleChange}
        />

        <TextArea
          name="message"
          label="Detalhes da Solicitação"
          placeholder="Digite sua mensagem"
          value={values.message}
          errorText={(touched.message && errors.message) || ''}
          disabled={loading}
          onChange={handleChange}
        />
      </div>

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.GATSBY_RECAPTCHA_CLIENT_KEY as string}
      />

      <Button
        className={styles.button}
        type="submit"
        variant="outline"
        size="lg"
        disabled={!values.name || !values.email || loading}
      >
        {loading && <Spinner size="xs" />}
        {loading ? 'Enviando...' : 'Enviar solicitação'}
        <Icon icon={faSend} />
      </Button>
    </form>
  );
};

export default DataInquiryForm;
